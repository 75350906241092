const menu = [
  {
    label: 'Projets',
    targetUrl: '/projets'
  },
  {
    label: 'À propos',
    targetUrl: '/a-propos'
  },
  {
    label: 'Actualités',
    targetUrl: 'https://www.instagram.com/beigbeder.gauin'
  },
  {
    label: 'Contacts',
    targetUrl: '/contacts'
  },
]

export default menu
