<template>

  <nav class="v-menu-bar">
    <div class="flex items-center justify-between mx-2">
      <div class="mx-1 w-48">
        <AppLink
          to="/projets"
        >
          <img src="/bga-sm.png">
        </AppLink>
      </div>

      <div>
        <a v-if="!touchMenuSeen" @click.stop="touchMenuState(true)" href="#">
          <svg class="inline w-8 mx-1 my-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
          </svg>
        </a>
        <a v-if="touchMenuSeen" @click.stop="touchMenuState(false)" href="#">
          <svg class="inline w-8 mx-1 my-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </a>
      </div>

    </div>
  </nav>

</template>

<script>
import AppLink from './AppLink.vue'

export default {
  name: 'MenuBar',

  components: {
    AppLink
  },

  props: {
    menu: {
      type: Array
    },
    touchMenuSeen: {
      type: Boolean
    }
  },

  methods: {
    touchMenuState(isOn) {
      this.$utils.fineDbgLog({MenuBar: this, touchMenuState: isOn})
      this.$emit('touchMenuState', isOn)
    }
  }
}
</script>
